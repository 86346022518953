<template>
  <div class="auth">
    <div class=" auth__container">
      <section
        class="auth__form-wrapper"
        :class="[`auth__form-wrapper--sign-in`, `${type === 'signUp' ? 'auth__form-wrapper--passive' : ''}`]"
      >
      <div class="auth__form-wrapper--inner">
        <h1 class="auth__title">{{ $t(`signIn.title`) }}</h1>
        <h2 class="auth__sub-title">{{ $t(`signIn.subTitle`) }}</h2>
        <sign-in-form  v-bind="$t(`signIn.formFields`)" class="auth__form auth__form--sign-in"/>
        </div>
      </section>
      <section class="auth__side" :class="[`auth__side--${slug}`]">
        <base-sub-heading class="auth__header-sub-heading" align="center">
          <span v-html="$t(`${type}.section.subTitle`)" />
        </base-sub-heading>
        <base-heading class="auth__header-heading" align="center">
          <span v-html="$t(`${type}.section.title`)" />
        </base-heading>
        <p class="auth__description">{{ $t(`${type}.section.description`) }}</p>
        <base-button type="outline" :to="url">
          {{ $t(`${type}.section.subTitle`) }}
        </base-button>
      </section>
      <section
        class="auth__form-wrapper"
        :class="[`auth__form-wrapper--sign-up`, `${type === 'signIn' ? 'auth__form-wrapper--passive' : ''}`]"
      >
      <div class="auth__form-wrapper--inner">
        <h1 class="auth__title">{{ $t(`signUp.title`) }}</h1>
        <h2 class="auth__sub-title">{{ $t(`signUp.subTitle`) }}</h2>
        <sign-up-form  v-bind="$t(`signUp.formFields`)" class="auth__form auth__form--sign-up"/>
      </div>
      </section>
    </div>
  </div>
</template>

<script>
import store from '@/store/';

export default {
  name: 'Auth',
  data: () => ({
    input: '',
  }),
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  computed: {
    type() {
      return this.slug === 'sign-up' ? 'signUp' : 'signIn';
    },
    passiveType() {
      return this.slug === 'sign-up' ? 'signIn' : 'signUp';
    },
    url() {
      return this.slug === 'sign-up' ? 'sign-in' : 'sign-up';
    },
  },
  beforeRouteUpdate(to, from, next) {
    const isValid = (param) => {
      const params = ['sign-in', 'sign-up'];
      return params.includes(param);
    };
    if (!isValid(to.params.slug)) {
      next({ path: 'sign-in' });
    } else {
      next();
    }
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['auth/isLogged']) {
      next({ path: '/dashboard' });
    }

    if (!store.getters['auth/isLogged']) {
      next();
    }
  },
};
</script>
<style lang="scss" scoped src="./Auth.scss" />
